@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #383636; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  } */
  .loading-spinner{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../public/assets/images/preloader.gif') 
                50% 50% no-repeat rgb(249,249,249);
  }
  